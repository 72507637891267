<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Team Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6" lg="4">
                    <v-switch
                        v-model="team.active"
                        hide-details
                        inset
                        :label="team.active ? 'Active' : 'Not Active'"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-text-field
                        v-model="team.name"
                        label="Name"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-autocomplete
                        v-model="team.league"
                        :items="leagues"
                        item-text="title"
                        menu-props="auto"
                        label="League"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="team.color"
                                class="mt-0 pt-4"
                                label="Team Color"
                                append-icon="mdi-menu-down"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <template v-slot:prepend>
                                    <v-icon large :color="team.color">
                                        mdi-palette
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-row class="py-10">
                            <v-col cols="12" align="center">
                                <v-color-picker
                                    v-model="team.color"
                                    dot-size="23"
                                    hide-inputs
                                    flat
                                    swatches-max-height="200"
                                />
                            </v-col>
                        </v-row>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="team.players"
                        :items="players"
                        :item-text="getPlayerText"
                        :loading="loading"
                        :search-input.sync="search"
                        label="Players"
                        hide-details
                        multiple
                        chips
                        clearable
                        return-object
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click.stop="openDialog(data.item)"
                                @click:close="remove(data.item)"
                            >
                                <v-avatar left>
                                    <v-img :src="googleApiHost + '/' + data.item.image"/>
                                </v-avatar>
                                {{ `${data.item.jersey} ${data.item.name} ${data.item.surname}` }}
                                <span class="orange--text">
                                    {{ '/ Active:' }}
                                    <v-icon :color="data.item.active ? 'green' : 'red'">
                                        {{ data.item.active ? 'mdi-check-outline' : 'mdi-close-outline' }}
                                    </v-icon>
                                    {{ '/ Doctor:' }}
                                    <v-icon :color="data.item.doctorCheck ? 'green' : 'red'">
                                        {{ data.item.doctorCheck ? 'mdi-check-outline' : 'mdi-close-outline' }}
                                    </v-icon>
                                </span>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="6" lg="3">
                    <v-file-input
                        v-model="logo"
                        class="mt-3"
                        accept="image/*"
                        show-size
                        label="Team Logo"
                        :clearable="clearable"
                        :rules="mandatoryField"
                        @change="changeLogo"
                        @click:clear="clearLogo()"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-img
                        v-if="logo"
                        width="100px"
                        height="auto"
                        contain
                        :src="logoPath"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-file-input
                        v-model="image"
                        class="mt-3"
                        accept="image/*"
                        show-size
                        label="Team Image"
                        :clearable="clearable"
                        :rules="mandatoryField"
                        @change="changeImage"
                        @click:clear="clearImage()"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-img
                        v-if="image"
                        width="200px"
                        height="auto"
                        contain
                        :src="imagePath"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn :loading="loading" class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
        <v-dialog
            v-model="dialog"
            width="90%"
        >
            <v-card>
                <player-form :propId="selectedPlayerId" @updatePlayer="updatePlayer"/>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import teamService from '@/services/team'
    import Alert from '@/components/Alert'
    import leagueService from '@/services/league'
    import playerService from '@/services/player'
    import PlayerForm from '@/forms/PlayerForm'

    export default {
        name: 'TeamForm',
        components: { Alert, PlayerForm },
        props: {
            teamName: {
                type: String,
                default: ''
            }
        },
        data: () => {
            return {
                selectedPlayerId: null,
                showAlert: false,
                dialog: false,
                message: null,
                type: null,
                mode: null,
                readOnly: false,
                clearable: true,
                loading: false,
                startDate: null,
                endDate: null,
                googleApiHost: 'https://storage.googleapis.com',
                team: {
                    color: '#000000',
                    players: []
                },
                leagues: [],
                teamId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                image: {
                    name: 'Image file'
                },
                imageChanged: false,
                imagePath: '',
                logo: {
                    name: 'Image file'
                },
                logoChanged: false,
                logoPath: '',
                mandatoryField: [
                    v => !!v || 'Field is required'
                ],
                players: [],
                search: null
            }
        },
        watch: {
            search() {
                if (this.search.length > 0) {
                    this.fetchFilteredPlayers()
                }
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.teamId = this.$route.params.teamId
            if (this.teamId) {
                teamService.getTeam(this.teamId).then(resp => {
                    this.team = resp.data
                    this.players = this.team.players
                    if (this.team.image) {
                        this.imagePath = this.googleApiHost + '/' + this.team.image
                    }
                    if (this.team.logo) {
                        this.logoPath = this.googleApiHost + '/' + this.team.logo
                    }
                    if (!this.team.color) {
                        this.team.color = '#000000'
                    }
                })
            } else {
                this.fetchAllPlayers()
                if (this.teamName) {
                    this.team.name = this.teamName
                }
            }
            this.getAllLeagues()
        },
        methods: {
            updatePlayer() {
                this.dialog = false
                teamService.getTeam(this.teamId).then(resp => {
                    this.team = resp.data
                    this.players = this.team.players
                    if (this.team.image) {
                        this.imagePath = this.googleApiHost + '/' + this.team.image
                    }
                    if (this.team.logo) {
                        this.logoPath = this.googleApiHost + '/' + this.team.logo
                    }
                    if (!this.team.color) {
                        this.team.color = '#000000'
                    }
                })
            },
            openDialog(data) {
                this.dialog = true
                this.selectedPlayerId = data.id
            },
            remove(item) {
                const index = this.team.players.indexOf(item)
                if (index >= 0) {
                    this.team.players.splice(index, 1)
                }
            },
            getPlayerText(item) {
                return `#${item.jersey} ${item.name} ${item.surname}`
            },
            clearImage() {
                this.imagePath = ''
                this.team.image = null
            },
            clearLogo() {
                this.logo = ''
                this.team.logo = null
            },
            changeImage() {
                this.imageChanged = true
                if (this.image) {
                    this.imagePath = URL.createObjectURL(this.image)
                }
            },
            changeLogo() {
                this.logoChanged = true
                if (this.logo) {
                    this.logoPath = URL.createObjectURL(this.logo)
                }
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return false
            },
            setStartDate(date) {
                this.startDate = date
            },
            setEndDate(date) {
                this.endDate = date
            },
            onEdit() {
                this.$router.push(`/teams/${this.teamId}/edit`)
            },
            getAllLeagues() {
                leagueService.getAllLeagues().then(resp => {
                    this.leagues = resp.data
                })
            },
            createTeam() {
                teamService.createTeam(this.team).then(teamResponse => {
                    if (teamResponse.id) {
                        this.teamId = teamResponse.id
                    }
                    this.loading = false
                    if (this.$route.name !== 'Team Application Form') {
                        this.$router.push(`/teams`)
                    } else {
                        this.$emit('removeTeamForm')
                    }
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editTeam() {
                teamService.updateTeam(this.teamId, this.team).then(teamResponse => {
                    if (teamResponse.id) {
                        this.teamId = teamResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/teams`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            async onSubmit() {
                this.loading = true
                const uploadImageResponse = await this.uploadImage()
                const uploadLogoResponse = await this.uploadLogo()
                const imageUrl = uploadImageResponse?.data.content || null
                if (imageUrl) {
                    this.team.image = imageUrl
                }
                const logoUrl = uploadLogoResponse?.data.content || null
                if (logoUrl) {
                    this.team.logo = logoUrl
                }
                if (this.mode === 'edit') {
                    this.editTeam()
                } else if (this.mode === 'create') {
                    this.createTeam()
                } else {
                    this.createTeam()
                }
            },
            uploadImage() {
                if (!this.imageChanged || this.image == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.image)
                return teamService.uploadFile(formData)
            },
            uploadLogo() {
                if (!this.logoChanged || this.logo == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.logo)
                return teamService.uploadFile(formData)
            },
            fetchFilteredPlayers() {
                this.loading = true
                playerService.getFilteredPlayers(this.search).then(resp => {
                    this.players = [...new Set(this.players.concat(resp.data.content))]
                    this.loading = false
                })
            },
            fetchAllPlayers() {
                this.loading = true
                playerService.getPlayers().then(resp => {
                    this.players = resp.data.content
                    this.loading = false
                })
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.v-menu__content{
    background-color: white!important;
    overflow-y: hidden!important;
}
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
